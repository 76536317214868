import {Stack} from "@mui/material";
import {PageInfo, SbTypography} from "@surebase/shared-component-library";
import InfiniteScroll from "react-infinite-scroll-component";
import {MessageBox} from "../../../services/message-box/graphql/MessageBox";
import {MessageListItem} from "./MessageListItem";
import {NotFoundCard} from "../../components/NotFoundCard";
import React from "react";

export interface MessageListProps {
	readonly onClick: (message: MessageBox.Message) => void;
	readonly fetchMoreData: () => Promise<void>;
	readonly messagesPageInfo: PageInfo | undefined;
	readonly messages: MessageBox.Message[];
	readonly markAsRead: (message: MessageBox.Message) => Promise<void>;
}

export function MessageList(props: MessageListProps) {
	if (props.messages.length === 0) {
		return <NotFoundCard heading={"No messages found"}
			subText={"When you receive a new message it will be displayed here."}/>;
	}
	
	const messages = props.messages;
	const messagesPageInfo = props.messagesPageInfo;
	return <div
		id="scrollableDiv"
		style={{
			overflowY: "auto",
			display: "flex",
			flexDirection: "column",
			paddingRight: "32px"
		}}
	>
		<InfiniteScroll
			dataLength={messages?.length ?? 0}
			next={async () => {
				await props.fetchMoreData();
			}}
			style={{display: "flex", flexDirection: "column"}}
			hasMore={messagesPageInfo?.hasNextPage ?? false}
			loader={<SbTypography>Loading...</SbTypography>}
			scrollableTarget="scrollableDiv"
		>
			<Stack gap={2} style={{overflowY: "auto", width: "400px"}}>
				{messages && messages.map(message => {
					return <MessageListItem
						key={message.id}
						message={message}
						onClick={props.onClick}
						markAsRead={props.markAsRead}
					/>;
				})}
			</Stack>
		</InfiniteScroll>
	</div>;
}
