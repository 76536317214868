import {gql} from "@apollo/client";

export const SUBSCRIBE_MESSAGES = gql`
	subscription($username: String!) {
		newMessageBoxChange(username: $username) {
			id
			messages {
				id
				creationDateTime
				senderPlatform
				messageCategory
				readDateTime
				header
				subject
				body
				links {
					id
					linkType
					name
					expiryDate
				}
			}
		}
	}
`;

export const GET_MESSAGE_BOX_DETAILS = gql`
	query messageBoxDetails
	{
		messageBoxDetails {
			unreadCount
		}
	}`;

export const GET_MESSAGES_FOR_USER = gql`
	query messagesForUser($cursorAfter:String,$pageSize:Int!)
	{
		messagesForUser(
			after: $cursorAfter
			first: $pageSize
			order:[
				{
					creationDateTime:DESC
				}
			]
		) {
			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}
			nodes {
				id
				creationDateTime
				senderPlatform
				messageCategory
				readDateTime
				header
				subject
				body
				links {
					id
					linkType
					name
					expiryDate
				}
			}
		}
	}`;

export const GET_MESSAGES_LINK_PRE_ASSIGNED_URL = gql`
	query messagesLinkPreAssignedUrl($messageId: UUID! $linkId: UUID!)
	{
		messagesLinkPreAssignedUrl(messageId:$messageId,linkId:$linkId) {
			downloadUrl
		}
	}`;

export const MARK_AS_READ = gql`
	mutation markAsRead($input: MarkAsReadInput!) {
		markAsRead(input:$input){
			mutationResult {
				success
			}
		}
	}`;

export const DELETE_MESSAGES = gql`
	mutation deleteMessages($input: DeleteMessagesInput!) {
		deleteMessages(input:$input){
			mutationResult {
				success
			}
		}
	}`;

