import React, {useEffect, useState} from "react";
import {
	formatter,
	Home02Icon,
	SbIconButton,
	SbMainCustomer,
	SbPage, SbUserSession,
	useMobileView,
	userSessionProvider,
	XCloseIcon,
} from "@surebase/shared-component-library";
import {Navigate, Route} from "react-router-dom";
import {Settings} from "../../global/Settings";
import {CustomerDetailsPage} from "../../pages/customer/CustomerDetailsPage";
import {ClaimsPage} from "../../pages/claims/ClaimsPage";
import {DashboardPage} from "../../pages/dashboard/DashboardPage";
import {InvitePage} from "../../pages/customer/InvitePage";
import {Box, Popover, useTheme} from "@mui/material";
import {ContractsPage} from "../../pages/contracts/ContractsPage";
import {InvoicesPage} from "../../pages/invoices/InvoicesPage";
import {ContactCard, ContactCardProps} from "../../pages/components/ContactCard";
import {PortalDataRepository} from "../../services/portal/graphql/PortalDataRepository";
import {Portal} from "../../services/portal/graphql/Portal";
import {SettingsPage} from "../../pages/settings/SettingsPage";
import {TenantHelper} from "../../helpers/TenantHelper";
import {MessageBoxPage} from "../../pages/messageBox/MessageBox";
import {Environment} from "../../global/Environment";
import {CookieBotHelper} from "../../helpers/CookieBotHelper";

export function Main() {
	const theme = useTheme();
	const inMobileView = useMobileView();
	
	useEffect(() => {
		TenantHelper.setTitleAndIcon();
		if (TenantHelper.isOverstappenTenant()) {
			CookieBotHelper.AddCookieBotWithStyles();
			if (Environment.overstappenTagId) {
				const script = document.createElement("script");
				script.src = `https://www.googletagmanager.com/gtag/js?id=${Environment.overstappenTagId}`;
				script.async = true;
				document.head.appendChild(script);
				
				const scriptContent = document.createElement("script");
				scriptContent.innerHTML = `
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', '${Environment.overstappenTagId}');
				`;
				document.head.appendChild(scriptContent);
				return () => {
					document.head.removeChild(script);
					document.head.removeChild(scriptContent);
				};
			}
		}
	}, []);
	
	return <SbMainCustomer
		inMobileView={inMobileView}
		HeaderProps={{
			search: TenantHelper.isOverstappenTenant() ? <></> : undefined,
			homeIcon: (
				<Box className={"policy-card-container"}>
					<Home02Icon height={24} width={24} color={theme.palette.text.primary}/>
				</Box>
			),
			customLogo: TenantLogoContent({userSession: userSessionProvider.userSession}),
			UserWidgetProps: {
				containerStyle: {
					position: "relative",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "flex-end"
				},
				disableMessages: true,
				disableWelcomeText: true,
				disableProfile: true,
				settingsLink: "/customer-portal/settings"
			},
			HayStackSearchProps: {
				style: {maxWidth: "unset"}
			},
		}}
		routes={(
			<>
				<Route path={"customer-portal"}>
					<Route index element={<SbPage child={<DashboardPage/>}/>}/>
					<Route path={"settings"} element={<SbPage child={<SettingsPage/>}/>}/>
					<Route path={"claims"} element={<SbPage child={<ClaimsPage/>}/>}/>
					<Route path={"contracts"} element={<SbPage child={<ContractsPage/>}/>}/>
					<Route path={"customer"} element={<SbPage child={<CustomerDetailsPage/>}/>}/>
					<Route path={"invite/:id"} element={<SbPage child={<InvitePage/>}/>}/>
					<Route path={"invoices"} element={<SbPage child={<InvoicesPage/>}/>}/>
					{TenantHelper.isSurebaseTenant() ?
						<Route path={"messages"} element={<SbPage key={"messages"} child={<MessageBoxPage/>}/>}/>
						: undefined}
				</Route>
				<Route path={"*"} element={<Navigate to="/customer-portal" replace/>}/>
			</>
		)}
		translationModules={[
			"SureBase",
			Settings.languageModule
		]}
		menuItems={[]}
	/>;
}

function TenantLogoContent(props: { userSession: SbUserSession | null }) {
	const inMobileView = useMobileView();
	const [popupOpen, setPopupOpen] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [contactCardDetails, setContactCardDetails] = useState<ContactCardProps>();
	
	useEffect(() => {
		if (props.userSession && TenantHelper.isOverstappenTenant()) {
			PortalDataRepository.getOverstappenDetails().then((result: Portal.BrokerPayload | null) => {
				if (!result)
					return;
				
				setContactCardDetails({
					heading: "Contact details",
					name: result.name,
					nameLink: "https://www.overstappen.nl",
					address: formatter.address({
						city: result.city,
						street: result.street,
						postalCode: result.postalCode,
						houseNumber: result.houseNumber,
						houseNumberAddition: result.houseNumberAddition
					}),
					contactNumber: formatter.contactNumber(result.contactNumber),
					emailAddress: result.email
				});
			});
		}
	}, [props.userSession]);
	
	
	if (inMobileView && TenantHelper.isSurebaseTenant())
		return <></>;
	
	const onLogoClick = (newState: boolean, event: any) => {
		event.stopPropagation();
		if (popupOpen === newState)
			return;
		
		setAnchorEl(newState ? event.currentTarget : null);
		setPopupOpen(newState);
	};
	
	if (TenantHelper.isOverstappenTenant()) {
		return <>
			<img src={"/overstappen-logo.png"} alt={"Overstappen"}
				style={{
					marginLeft: inMobileView ? "unset" : "auto",
					width: "127px",
					height: "18px",
					objectFit: "contain",
					display: "block",
					overflow: "hidden",
					cursor: "pointer"
				}}
				onClick={(event) => onLogoClick(!popupOpen, event)}/>
			{(!inMobileView && contactCardDetails) && (
				<Popover
					anchorEl={anchorEl}
					open={popupOpen}
					onClose={(event) => onLogoClick(false, event)}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					slotProps={{
						paper: {
							style: {
								width: "280px"
							}
						}
					}}
				>
					<ContactCard {...contactCardDetails}/>
				</Popover>
			)}
			{(inMobileView && contactCardDetails) && (
				<div className={`card-container ${popupOpen ? "visible" : ""}`}>
					<ContactCard {...contactCardDetails}
						actionSlot={(
							<SbIconButton IconButtonProps={{
								style: {marginLeft: "auto", padding: "0px"},
								onClick: (event) => onLogoClick(!popupOpen, event)
							}}>
								<XCloseIcon height={24} width={24}/>
							</SbIconButton>
						)}/>
				</div>
			)}
		</>;
	}
	
	return <img src={"/risk-direct.png"} alt={"Risk Direct"}
		style={{
			width: "75.5px",
			position: "relative",
			height: "40px",
			objectFit: "cover"
		}}/>;
}
