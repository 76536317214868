import {Dossiers} from "../services/dossiers/graphql/Dossiers";
import {DossierDownload} from "@surebase/shared-component-library";
import {DossierDataRepository} from "../services/dossiers/graphql/DossierDataRepository";

export class DossierHelper {
	
	static mimeTypes: Record<string, string> = {
		"txt": "text/plain",
		"html": "text/html",
		"css": "text/css",
		"js": "application/javascript",
		"json": "application/json",
		"csv": "text/csv",
		"xml": "application/xml",
		"pdf": "application/pdf",
		"jpeg": "image/jpeg",
		"jpg": "image/jpeg",
		"png": "image/png",
		"gif": "image/gif",
		"bmp": "image/bmp",
		"svg": "image/svg+xml",
		"webp": "image/webp",
		"mp3": "audio/mpeg",
		"wav": "audio/wav",
		"ogg": "audio/ogg",
		"mp4": "video/mp4",
		"webm": "video/webm",
		"avi": "video/x-msvideo",
		"zip": "application/zip",
		"gz": "application/gzip",
		"rar": "application/vnd.rar",
		"7z": "application/x-7z-compressed",
		"woff": "font/woff",
		"woff2": "font/woff2",
		"ttf": "font/ttf",
		"otf": "font/otf"
	};
	
	static attachmentTypeLookup: Record<string, string> = {
		V2: "Audatex calculatierapport",
		V3: "Samenvatting calculatierapport",
		V4: "Foto",
		V5: "Politie rapport",
		V6: "Polis Informatie Berichten (PBI)",
		V7: "Polis aanvraag-/mutatie bevestiging bericht (PMB)",
		V8: "Polis prolongatie berichten (PPR)",
		V9: "Rekening courant berichten (PRI)",
		V10: "Elektronische standaard brieven (ESB)",
		V11: "Offerte",
		V12: "(Pakket) Polis",
		V13: "Prolongatienota",
		V14: "Royementsverklaring",
		V15: "Voorwaarden",
		V16: "Brief",
		V19: "Boekingsnota",
		V25: "Aanvraag",
		V26: "Kopie (Pakket) Polis",
		V27: "(Kopie) Factuur klant",
		V28: "Prolongatie batch volmachten",
		V29: "Volmacht prolongatie bericht",
		V30: "Werknemersoverzicht",
		V31: "Bevestiging schadevergoeding",
		V32: "Schade aangifte",
		V33: "Voorlopige dekking",
		V34: "Groene kaart",
		V35: "Polisbescheiden",
		V36: "Kopie identiteitsbewijs",
		V38: "Opzegkaart ARBO dienst",
		V40: "Aankoopnota",
		V42: "Kopie verzekeringsbescheiden",
		V44: "Rekening-courant",
		V45: "Provisieoverzicht",
		V46: "Borderel",
		V47: "Polisaanhangsel, Clausuleblad",
		V48: "Brief Model de Ruiter",
		V49: "Compensatiebrief",
		V50: "Rappelbrief",
		V51: "Aanbiedingsbrief",
		V52: "Brief verlenging contract",
		V53: "Brief nieuwe rekening",
		V54: "(Kopie) Verzekeringsbewijs",
		V55: "(Kopie) Verzekeringsoverzicht",
		V56: "Brief incassowijziging",
		V57: "Brief overlijdensmelding",
		V58: "Brief afwijzing aanvraag",
		V59: "Brief verhoogd risico",
		V60: "Brief premievrijmaking",
		V61: "Brief adreswijziging",
		V62: "Schadespecificatie",
		V63: "Brief acceptatiebevestiging",
		V64: "ARBO Rapportage",
		V65: "Gezondheidsverklaring",
		V66: "Fiscale opgave",
		V67: "Brief jaarlijkse check",
		V68: "Bevestiging vervallen aanvraag/mutatie",
		V69: "Brief betaalachterstand",
		V70: "Correspondentiebatch",
		V71: "Machtigingsformulier elektronisch wijzigen",
		V72: "Aanvullende informatie",
		V74: "Kostenstructuur overzicht",
		V75: "Startbrief",
		V76: "Pensioenreglement",
		V77: "Wettelijke wijziging",
		V78: "Brief afkoopwaarde",
		V79: "Uitvoeringsovereenkomst",
		V81: "Overzicht pensioenaanspraken",
		V83: "Diensten overzicht",
		V85: "Verzoek tot uitbetaling schade",
		V86: "BTW overzicht",
		V87: "Garantie verklaring",
		V88: "Brief voorwaarden- en/of premiewijziging",
		V89: "Laatste kennisgeving",
		V90: "Winstbrief",
		V91: "Naverrekeningsformulier",
		V92: "Naverrekeningsaanhangsel",
		V93: "Verzekeringskaart",
		V94: "Reispas",
		V95: "Slotverklaring",
		V96: "Privacy statement",
		V97: "Internationaal Verzekeringscertificaat (boten)",
		V98: "Italië-certificaat (boten)",
		VA1: "Aansprakelijkstelling",
		VA2: "Adviesbrief aan klant",
		VA3: "Bevestiging ontvangen schademelding",
		VA4: "Bevestiging sluiten schadedossier",
		VA5: "Bevestiging afkoop",
		VA6: "Bevestiging arbeidsongeschiktheid",
		VA7: "Brief opvragen stukken",
		VA8: "Brief m.b.t. beëindiging contract",
		VA9: "Brief vrijval kapitaal",
		VB1: "Betalingsherinnering",
		VB2: "Eerste aanmaning",
		VB3: "Tweede aanmaning",
		VB4: "Brief wijziging persoonlijke omstandigheden",
		VB5: "Brief wijziging contractgegevens",
		VB6: "Brief verzoek controle polisgegevens",
		VB7: "Brief bevestiging adviseurswijziging",
		VC1: "Bevestiging ontvangen contract aanvraag",
		VC2: "Bevestiging ontvangen contract mutatie aanvraag",
		VC3: "Bevestiging ontvangen premie aanvraag",
		VC4: "Premieberekening resultaat",
		VC5: "Ontvangstbevestiging",
		VD1: "Machtiging voor inzage",
		VD2: "Intrekken machtiging voor inzage",
		VD3: "Collectieve contractafspraken",
		VD4: "Naverrekeningsspecificatie",
		VD5: "Mailing, initiatief tussenpersoon",
		VD6: "Brief bevestiging uitkering",
		VD7: "Expertiserapport",
		VD8: "Proces verbaal",
		VD9: "Getuigenverklaring",
		VE1: "Akte van cessie",
		VE2: "Vaststellingsovereenkomst",
		VE3: "Opzegkaart",
		VE4: "Waardecoupon",
		VE5: "Rentespecificatie",
		VE6: "Overzicht incasso status",
		VE7: "Inventarisatieoverzicht",
		VE8: "Vergelijkingsoverzicht",
		VE9: "Adviesrapport",
		VF1: "Premieoverzicht/-specificatie",
		VF2: "Statusbericht",
		VF3: "Brief conversie polisbestand",
		VF4: "Conversiebestand polisgegevens",
		VF5: "SEPA incasso machtigingsformulier",
		VF6: "UBO verklaring",
		VF7: "Inboedelwaardemeter",
		VF8: "Herbouwwaardemeter",
		VF9: "Preventiebrochure",
		VG1: "Brief m.b.t. opschorting contract",
		VG6: "Aanbiedingsbrief met polisblad/verzekeringsbewijs",
		VG7: "Preventieverklaring",
		VG8: "Brief wijziging KVK gegevens",
		VG9: "Bevestiging afwijzing claim",
		VH1: "Bevestiging gesprek/afspraken",
		VH2: "Brief premie nieuw verzekeringsjaar",
		VH3: "Formulier jaarlijkse check",
		VH4: "Bevestiging ontvangen jaarlijkse check",
		VH5: "Klantenoverzicht",
		VH6: "Royementsaanhangsel",
		VH7: "Acceptatieverslag",
		VH8: "Verklaring verzekerde",
		VH9: "Verklaring tegenpartij",
		VI1: "Lease/financieringsovereenkomst",
		VI2: "Diefstal aangifte",
		VI3: "Alarmcertificaat",
		VI4: "Verwerping gesprek/afspraken",
		VI5: "Akte van benoeming",
		VI6: "Kentekenbewijs",
		VI7: "Diefstal rapport",
		VI8: "Sleutelinformatie",
		VJ1: "FISH-bevraging aanroep",
		VJ2: "FISH-bevraging resultaat",
		VJ3: "UBO-bevraging aanroep",
		VJ4: "UBO-bevraging resultaat"
	};
	
	static buildDossierDownloads(dossiers: Dossiers.Dossier[] | null): DossierDownload[] {
		if (!dossiers)
			return [];
		
		const documentDownloads: DossierDownload[] = [];
		const length = dossiers.length;
		const uniqueDocuments: string[] = [];
		for (let i = 0; i < length; i++) {
			const document = dossiers[i];
			if (!document || !document.attachmentType || uniqueDocuments.includes(document.attachmentType))
				continue;
			
			uniqueDocuments.push(document.attachmentType);
			documentDownloads.push({
				description: DossierHelper.getDocumentName(document),
				dossierId: document.id,
				onDownload(contractId: string, dossierId: string) {
					DossierDataRepository.getDownloadUrlForDossier(dossierId).then((downloadFile: Dossiers.DownloadFile | null) => {
						if (!downloadFile)
							return;
						
						const bytes = DossierHelper.base64ToArrayBuffer(downloadFile.base64File);
						const extension = downloadFile.fileName.split(".").pop()?.toLowerCase() || "";
						const mimeType = DossierHelper.mimeTypes[extension];
						if (mimeType) {
							const blob = new Blob([bytes],  {type: mimeType});
							const url = URL.createObjectURL(blob);
							window.open(url, "_blank");
							URL.revokeObjectURL(url);
						} else {
							DossierHelper.saveByteArray(downloadFile.fileName, bytes, downloadFile.contentType);
						}
					});
				}
			});
		}
		
		return documentDownloads;
	}
	
	static getDocumentName(dossier: Dossiers.Dossier): string {
		if (dossier.attachmentType) {
			const documentName = DossierHelper.attachmentTypeLookup[dossier.attachmentType];
			if (documentName !== "")
				return documentName;
		}
		
		if (dossier.documentDescription)
			return dossier.documentDescription;
		else if (dossier.fileName)
			return dossier.fileName;
		else if (dossier.fileNumber)
			return dossier.fileNumber;
		
		return "No name document";
	}
	
	static saveByteArray(reportName : string, byte: Uint8Array, contentType: string) {
		const blob = new Blob([byte], {type: contentType}); //"application/pdf"
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		link.download = reportName;
		link.click();
	}
	
	
	static base64ToArrayBuffer(base64: string) {
		const binaryString = window.atob(base64);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		return bytes;
	}
}
