import React from "react";
import {Card, CardActionArea, CardHeader, Divider, Stack, useTheme} from "@mui/material";
import {
	formatter,
	parseLocalDateTime,
	SbEllipse,
	SbTypographyRaw,
} from "@surebase/shared-component-library";
import {MessageBox} from "../../../services/message-box/graphql/MessageBox";
import {grey} from "@mui/material/colors";

export interface MessageItemProps {
	readonly message: MessageBox.Message;
	readonly onClick: (message: MessageBox.Message) => void;
	readonly markAsRead: (message: MessageBox.Message) => Promise<void>;
}

export function MessageListItem(props: MessageItemProps) {
	const theme = useTheme();
	const primaryText = theme.palette.text.primary;
	const secondaryText = theme.palette.text.secondary;
	const messageCategory = formatter.enum(props.message.messageCategory);
	return <Card variant={"outlined"}>
		<CardHeader title={
			<Stack direction={"row"} gap={1} alignItems={"center"}>
				{!props.message.readDateTime && (
					<SbEllipse style={{margin: undefined, marginTop: "auto", marginBottom: "auto"}}/>
				)}
				<SbTypographyRaw variant={"cardButtonHeader3"} color={primaryText}
					style={{
						textOverflow: "ellipsis",
						maxWidth: "300px",
						display: "inline-block",
						whiteSpace: "nowrap",
						overflow: "hidden"
					}}>
					{props.message.header}
				</SbTypographyRaw>
				<SbTypographyRaw variant={"body2"} color={secondaryText} style={{marginLeft: "auto"}}>
					{formatter.dateTime(parseLocalDateTime(props.message.creationDateTime), {
						year: "numeric",
						month: "short",
						day: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
						hour12: false
					})}
				</SbTypographyRaw>
			</Stack>
		} style={{backgroundColor: "#FFFFFF", padding: "16px", gap: "24px"}}/>
		<Divider color={grey[300]}/>
		<CardActionArea style={{padding: "16px", borderTopLeftRadius: "0px", borderTopRightRadius: "0px"}}
			onClick={async () => {
				await props.markAsRead(props.message);
				props.onClick(props.message);
			}}>
			<Stack style={{width: "100%"}}>
				<SbTypographyRaw variant={"cardButtonContent2"} color={primaryText}>
					{props.message.senderPlatform} {messageCategory} &#8208;
				</SbTypographyRaw>
				<SbTypographyRaw variant={"cardButtonContent2"} color={secondaryText}>
					{props.message.subject}
				</SbTypographyRaw>
			</Stack>
		</CardActionArea>
	</Card>;
}
